import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { Button } from 'antd';

const LoginButton = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const redirectUri = `${location.pathname}${location.search}`;

  return (
    !isAuthenticated &&
    !isLoading && (
      <Button onClick={() => loginWithRedirect({ appState: { returnTo: redirectUri } })}>
        Log In
      </Button>
    )
  );
};

export default LoginButton;
