import { useAuth0 } from '@auth0/auth0-react';

const HomeBranchName = () => {
  const { user } = useAuth0();

  if (!user?.branches?.length) {
    return null;
  }

  const homeBranch = user?.branches?.find((branch) => branch?.isHomeBranch);

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <span>{user?.company?.displayName}</span>
      {homeBranch?.displayName && (
        <span
          className="material-symbols-outlined"
          style={{
            color: 'rgba(0, 0, 0, 0.25)',
            padding: '0px 3px',
            position: 'relative',
            top: '5px',
            fontSize: '20px'
          }}
        >
          chevron_right
        </span>
      )}

      <span title="Home Branch">{homeBranch?.displayName}</span>
    </div>
  );
};

export default HomeBranchName;
