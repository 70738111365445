import { ConfigProvider, Layout, Spin } from 'antd';

const pageTheme = {
  components: {
    colorBgLayout: '#000000',
    Tooltip: {
      colorBgSpotlight: '#f5fafe',
      colorTextLightSolid: 'rgba(0, 0, 0, 0.88)',
      paddingSM: 16,
      paddingXS: 16
    },
    Menu: {
      radiusSubMenuItem: 0,
      radiusItem: 0,
      colorActiveBarBorderSize: 0,
      itemMarginInline: 0,
      lineWidthBold: 0,
      colorActiveBarHeight: 0,
      itemBg: '#ffffff',
      subMenuItemBg: '#ffffff',
      itemHoverBg: '#e6f4ff77',
      itemHoverColor: '#1677ff',
      marginXXS: 0,
      itemBorderRadius: 0,
      collapsedIconSize: 20,
      iconSize: 16,
      iconMarginInlineEnd: 10,
      margin: 8,
      lineType: 'none'
    },
    Table: {
      headerBorderRadius: 0,
      rowExpandedBg: '#ffffff'
    }
  }
};

const PageConfigProvider = ({ children, loading = false, ...otherProps }) => (
  <ConfigProvider theme={pageTheme} {...otherProps}>
    <Spin
      style={{
        maxHeight: '100%'
      }}
      spinning={loading}
      size="large"
      tip="Loading..."
    >
      <Layout
        style={{
          height: '100vh'
        }}
      >
        {children}
      </Layout>
    </Spin>
  </ConfigProvider>
);

export default PageConfigProvider;
