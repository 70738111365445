import styled from 'styled-components';
import { Menu, Spin, Card, Avatar, Grid } from 'antd';
import { Link, generatePath, matchPath } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from '../auth/LoginButton';
import { getBranchId, PROFILE_PATH } from '../../configs/routes';

const { Meta } = Card;

const ProfileInfo = styled(({ className, picture, email, name }) => (
  <Card className={className} type="inner" bordered size="small">
    <Meta
      avatar={<Avatar size="large" src={<img src={picture} alt="Picture" />} />}
      title={name}
      description={email}
    />
  </Card>
))`
  border: 0;
  .ant-card-body {
    position: relative;
    padding: 10px 0;
    left: -2px;
    top: 2px;
    .ant-card-meta-title {
      padding-bottom: 0px;
    }
  }
`;

const { useBreakpoint } = Grid;

const HeaderMenu = styled(({ className }) => {
  const screens = useBreakpoint();
  const { logout, user, isAuthenticated, isLoading } = useAuth0();
  const defaultSelectedKeys = [];
  const profilePath = generatePath(PROFILE_PATH, {
    branchId: getBranchId()
  });

  if (matchPath(location.pathname, profilePath) !== null) {
    defaultSelectedKeys.push('profile');
  }

  const items = [
    {
      label: !screens?.lg ? null : <span>{user?.nickname || user?.name}</span>,
      key: 'SubMenu',
      icon: (
        <img
          src={user?.picture}
          alt="Profile"
          width="28"
          className="profile_icon"
        />
      ),
      children: [
        {
          label: <ProfileInfo {...user} />,
          type: 'group'
        },
        {
          label: <Link to={profilePath}>Profile</Link>,
          key: 'profile',
          title: 'Profile'
        },
        {
          label: 'Log Out',
          key: 'log-out',
          title: 'Log Out',
          onClick: () =>
            logout({ logoutParams: { returnTo: window.location.origin } })
        }
      ]
    }
  ];

  if (isLoading) {
    return (
      <div className={className}>
        <Spin />
      </div>
    );
  }

  return isAuthenticated ? (
    <Menu
      className={className}
      defaultSelectedKeys={defaultSelectedKeys}
      items={items}
      mode="horizontal"
      selectable={false}
      disabledOverflow
    />
  ) : (
    <div className={className}>
      <LoginButton />
    </div>
  );
})`
  float: right;
  text-align: right;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 63px;

  .profile_icon {
    position: relative;
    top: 7px;
    border-radius: 50%;
  }
`;

export default HeaderMenu;
