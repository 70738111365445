import { Result } from 'antd';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import PageConfigProvider from '../configs/PageConfigProvider';
import PageHeader from '../headers/PageHeader';
import LoginButton from '../auth/LoginButton';

const errorResults = {
  403: {
    status: 403,
    title: 'Not Authorized',
    subTitle: 'Sorry, you are not authorized to access this page.',
    extra: <LoginButton />
  },
  404: {
    status: 404,
    title: 'Page Not Found',
    subTitle: 'Sorry, the page you visited does not exist.'
  },
  500: {
    status: 500,
    title: 'Oops...',
    subTitle: 'Sorry, something went wrong.'
  },
  503: {
    status: 500,
    title: 'API Is Down',
    subTitle: 'Sorry, looks like our API is down.'
  }
};

const RouterErrorBoundary = () => {
  const error = useRouteError();

  let errorResult = errorResults['500'];

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      errorResult = errorResults['404'];
    }

    if (error.status === 401 || error.status === 403) {
      errorResult = errorResults['403'];
    }

    if (error.status === 503) {
      errorResult = errorResults['503'];
    }
  }

  return (
    <PageConfigProvider>
      <PageHeader />
      <Result
        status={errorResult.status}
        title={errorResult.title}
        subTitle={errorResult.subTitle}
        extra={errorResult.extra}
      />
    </PageConfigProvider>
  );
};

export default RouterErrorBoundary;
