import styled from 'styled-components';
import logo from '../../logo.svg';
import { Layout, Row, Col, Grid } from 'antd';
const { useBreakpoint } = Grid;
import { Link } from 'react-router-dom';
import Wrapper from '../configs/Wrapper';
import HeaderMenu from '../../components/menus/HeaderMenu';
import HomeBranchName from '../menus/HomeBranchName';
import { DASHBOARD_PATH } from '../../configs/routes';

const PageHeader = styled(({ className, children, ...otherProps }) => {
  const { xs } = useBreakpoint();
  return (
    <Layout.Header className={className} {...otherProps}>
      <Wrapper>
        <Row wrap={false}>
          <Col flex={xs ? '50px' : '135px'} className="logo_cotainer">
            <Link
              style={{ width: xs ? '22px' : 'auto' }}
              to={DASHBOARD_PATH}
              data-testid="logo"
              title="FX BRANCH"
            >
              <img src={logo} className="logo" alt="logo" data-testid="logo" />
            </Link>
          </Col>
          <Col flex="auto" className="header_menu_cotainer">
            <HomeBranchName />
          </Col>
          <Col flex="auto">{children}</Col>
          <Col flex="auto">
            <HeaderMenu />
          </Col>
        </Row>
      </Wrapper>
    </Layout.Header>
  );
})`
  background-color: #ffffff;
  border-bottom: 1px solid var(--light-gray);
  padding: 0;

  .logo_cotainer {
    font-weight: 700;
    font-size: 16px;
    overflow: hidden;
    padding-left: 16px;

    a {
      display: block;
      overflow: hidden;
    }

    .logo {
      width: 88px;
      position: relative;
      top: 0px;
    }
  }
`;

export default PageHeader;
