import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { Auth0Provider } from '@auth0/auth0-react';
import { RouterProvider } from 'react-router-dom';
import { App } from 'antd';
import './index.css';

import router from './router';
import PageSpinner from './components/spinners/PageSpinner';
import ErrorBoundary from './components/boundaries/ErrorBoundary';

const providerConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    scope: 'openid profile email',
    redirect_uri: window.location.origin,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE
  }
};

createRoot(document.getElementById('root')).render(
  <RecoilRoot>
    <ErrorBoundary>
      <Suspense fallback={<PageSpinner />}>
        <Auth0Provider {...providerConfig}>
          <App>
            <RouterProvider router={router} fallbackElement={<PageSpinner />} />
          </App>
        </Auth0Provider>
      </Suspense>
    </ErrorBoundary>
  </RecoilRoot>
);
