import { theme } from 'antd';

const Wrapper = ({ children, ...otherProps }) => {
  const {
    token: { screenXXL }
  } = theme.useToken();

  return (
    <div
      {...otherProps}
      style={{
        margin: '0 auto',
        maxWidth: `${screenXXL}px`
      }}
    >
      {children}
    </div>
  );
};

export default Wrapper;
