import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  useNavigation,
  Outlet,
  useLocation,
  useNavigate
} from 'react-router-dom';
import PageSpinner from './components/spinners/PageSpinner';
import RouterErrorBoundary from './components/boundaries/RouterErrorBoundary';
import PrivateRoute from './components/auth/PrivateRoute';

import {
  DASHBOARD_PATH,
  PROFILE_PATH,
  COMPANY_PATH,
  BRANCH_PATH,
  USER_PATH,
  makeCompanyPath,
  makeCompanyEditPath,
  makeBranchPath,
  makeUserPath,
  makeCurrencyPath,
  CURRENCY_PATH,
  CURRENCY_API_PATH,
  makeCustomerPath,
  makeBusinessPath,
  makeTransactionPath,
  makeBranchTransactionPath,
  makeBranchTransactionReviewPath,
  makeTransactionWholesalePath,
  makeWholesaleTransactionReceiptPath,
  makeTransactionReceiptPath,
  makeTransferReceiptPath,
  makeDomesticTransactionPath,
  SPOT_RATES_PATH,
  BUSINESS_PATH,
  CUSTOMER_PATH,
  ACCOUNTING_PATH,
  DOMESTIC_TRANSACTION_TYPES_PATH,
  makeDomesticTransactionReceiptPath,
  BALANCE_OPENING_CLOSING_PATH,
  CURRENCY_BALANCE_PATH,
  CURRENCY_BALANCE_BY_BRANCH_PATH,
  makeBalancePath,
  TRANSFER_BRANCH_PATH,
  CUSTOMER_EDIT_PATH,
  makeCustomerOrderGroupPath,
  makeOrderPath,
  makeOrderGroupReceiptPath,
  COMPARE_RATES_PATH,
  makeCurrencyTransactionReportPath,
  makeMultipleTransactionsPath,
  makeOFACSanctionsPath,
  DOWNLOADS_PATH
} from './configs/routes';

const Root = () => {
  const navigation = useNavigation();
  const location = useLocation();
  const navigate = useNavigate();

  // auth0 redirect fix
  useEffect(() => {
    if (
      navigation.state === 'idle' &&
      location.pathname &&
      location.pathname !== window.location.pathname
    ) {
      navigate(`${window.location.pathname}${window.location.search}`);
    }
  }, [
    location,
    navigation.state,
    location.pathname,
    navigate,
    window.location.pathname
  ]);

  if (navigation.state === 'loading' && navigation.formData == null) {
    return <PageSpinner />;
  }

  return <Outlet />;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Root />
      </PrivateRoute>
    ),
    errorElement: <RouterErrorBoundary />,
    children: [
      {
        lazy: () => import('./pages/dashboard'),
        path: DASHBOARD_PATH,
        index: true
      },
      {
        path: PROFILE_PATH,
        lazy: () => import('./pages/profile')
      },
      {
        path: makeBranchTransactionPath(
          ':companyId',
          ':branchId',
          'buy',
          ':customerTransactionId?'
        ),
        lazy: () => import('./pages/transactionBuy')
      },
      {
        path: makeBranchTransactionPath(
          ':companyId',
          ':branchId',
          'sell',
          ':customerTransactionId?'
        ),
        lazy: () => import('./pages/transactionSell')
      },
      {
        path: makeBranchTransactionReviewPath(
          ':companyId',
          ':branchId',
          ':transactionId',
          ':customerTransactionId'
        ),
        lazy: () => import('./pages/transactionReview')
      },
      {
        path: makeTransactionPath(':companyId', ''),
        lazy: () => import('./pages/transactions')
      },
      {
        path: makeTransactionPath(':companyId', ':transactionId'),
        lazy: () => import('./pages/transaction')
      },
      {
        path: makeTransactionReceiptPath(':companyId', ':transactionGroupId'),
        lazy: () => import('./pages/transactionReceipt')
      },
      {
        path: makeTransferReceiptPath(':companyId', ':transactionGroupId'),
        lazy: () => import('./pages/transferReceipt')
      },
      {
        path: ACCOUNTING_PATH,
        lazy: () => import('./pages/accounting')
      },
      {
        path: makeTransactionWholesalePath(':companyId', 'new', ''),
        lazy: () => import('./pages/wholesaleTransactionAdd')
      },
      {
        path: makeWholesaleTransactionReceiptPath(
          ':companyId',
          ':transactionGroupId'
        ),
        lazy: () => import('./pages/wholesaleTransactionReceipt')
      },
      {
        path: makeDomesticTransactionPath(':companyId', ':branchId', 'new'),
        lazy: () => import('./pages/domesticTransactionAdd')
      },
      {
        path: makeDomesticTransactionPath(':companyId', ':branchId', ''),
        lazy: () => import('./pages/domesticTransactions')
      },
      {
        path: makeDomesticTransactionPath(
          ':companyId',
          ':branchId',
          ':domesticTransactionId'
        ),
        lazy: () => import('./pages/domesticTransaction')
      },
      {
        path: makeDomesticTransactionReceiptPath(
          ':companyId',
          ':domesticTransactionId'
        ),
        lazy: () => import('./pages/domesticTransactionReceipt')
      },
      {
        path: DOMESTIC_TRANSACTION_TYPES_PATH,
        lazy: () => import('./pages/domesticTransactionTypes')
      },
      {
        path: BALANCE_OPENING_CLOSING_PATH,
        lazy: () => import('./pages/balanceOpeningClosing')
      },
      {
        path: CURRENCY_BALANCE_PATH,
        lazy: () => import('./pages/currencyBalance')
      },
      {
        path: CURRENCY_BALANCE_BY_BRANCH_PATH,
        lazy: () => import('./pages/currencyBalanceByBranch')
      },
      {
        path: makeBalancePath(':companyId', ':branchId', ''),
        lazy: () => import('./pages/balances')
      },
      {
        path: makeBalancePath(':companyId', ':branchId', ':balanceId'),
        lazy: () => import('./pages/balance')
      },
      {
        path: TRANSFER_BRANCH_PATH,
        lazy: () => import('./pages/transferBranch')
      },
      {
        path: makeCustomerPath(':companyId', ''),
        lazy: () => import('./pages/customers')
      },
      {
        path: makeCustomerPath(':companyId', 'new'),
        lazy: () => import('./pages/customerAdd')
      },
      {
        path: CUSTOMER_EDIT_PATH,
        lazy: () => import('./pages/customerEdit')
      },
      {
        path: CUSTOMER_PATH,
        lazy: () => import('./pages/customerView')
      },
      {
        path: makeBusinessPath(':companyId', ''),
        lazy: () => import('./pages/businesses')
      },
      {
        path: makeBusinessPath(':companyId', 'new'),
        lazy: () => import('./pages/businessAdd')
      },
      {
        path: BUSINESS_PATH,
        lazy: () => import('./pages/businessView')
      },

      {
        path: makeCompanyPath(''),
        lazy: () => import('./pages/companies')
      },
      {
        path: makeCompanyPath(),
        lazy: () => import('./pages/companyAdd')
      },
      {
        path: makeCompanyEditPath(':companyId'),
        lazy: () => import(`./pages/companyEdit`)
      },
      {
        path: COMPANY_PATH,
        lazy: () => import(`./pages/companyView`)
      },
      {
        path: makeBranchPath(':companyId', 'new'),
        lazy: () => import('./pages/branchAdd')
      },
      {
        path: BRANCH_PATH,
        lazy: () => import('./pages/branch')
      },
      {
        path: makeUserPath(':companyId', ''),
        lazy: () => import('./pages/users')
      },
      {
        path: makeUserPath(':companyId', 'new'),
        lazy: () => import('./pages/userAdd')
      },
      {
        path: USER_PATH,
        lazy: () => import('./pages/userEdit')
      },
      {
        path: makeCurrencyPath(':companyId', ''),
        lazy: () => import('./pages/currencies')
      },
      {
        path: COMPARE_RATES_PATH,
        lazy: () => import('./pages/compareRates')
      },
      {
        path: makeCurrencyPath(':companyId', 'manage'),
        lazy: () => import('./pages/currenciesEdit')
      },
      {
        path: CURRENCY_PATH,
        lazy: () => import('./pages/currencyEdit')
      },
      {
        path: SPOT_RATES_PATH,
        lazy: () => import('./pages/spotRates')
      },
      {
        path: CURRENCY_API_PATH,
        lazy: () => import('./pages/currenciesApi')
      },
      {
        path: makeCustomerOrderGroupPath(
          ':companyId',
          ':branchId',
          'new',
          ':customerTransactionId?'
        ),
        lazy: () => import('./pages/orderAdd')
      },
      {
        path: makeOrderPath(':companyId', ':branchId', ''),
        lazy: () => import('./pages/orders')
      },
      {
        path: makeOrderPath(':companyId', ':branchId', ':orderId'),
        lazy: () => import('./pages/order')
      },
      {
        path: makeOrderGroupReceiptPath(':companyId', ':orderGroupId'),
        lazy: () => import('./pages/orderReceipt')
      },
      {
        path: makeCurrencyTransactionReportPath(':companyId', ''),
        lazy: () => import('./pages/currencyTransactionReports')
      },
      {
        path: makeMultipleTransactionsPath(':companyId', ''),
        lazy: () => import('./pages/multipleTransactions')
      },
      {
        path: makeOFACSanctionsPath(':companyId', 'search'),
        lazy: () => import('./pages/ofacSearch')
      },
      {
        path: DOWNLOADS_PATH,
        lazy: () => import('./pages/downloadTransactions')
      }
    ]
  }
]);

export default router;
