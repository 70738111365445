import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';

export const DASHBOARD_PATH = '/dashboard?';
export const PROFILE_PATH = '/profile';
export const COMPANY_PATH = '/companies/:companyId';
export const BRANCH_PATH = '/companies/:companyId/branches/:branchId';
export const CUSTOMER_PATH = '/companies/:companyId/customers/:customerId';
export const CUSTOMER_EDIT_PATH = `${CUSTOMER_PATH}/edit`;
export const BUSINESS_PATH = '/companies/:companyId/businesses/:businessId';
export const WHOLESALE_PATH = '/companies/:companyId/wholesale/:wholesaleId';
export const USER_PATH = '/companies/:companyId/users/:userId';
export const CURRENCY_PATH = '/companies/:companyId/currencies/:currencyCode';
export const COMPARE_RATES_PATH = '/companies/:companyId/compare-rates';
export const TRANSACTION_PATH =
  '/companies/:companyId/transactions/:transactionId';
export const BRANCH_TRANSACTION_PATH =
  '/companies/:companyId/branches/:branchId/transactions/:transactionId/customers/:customerTransactionId';
export const BRANCH_TRANSACTION_REVIEW_PATH =
  '/companies/:companyId/branches/:branchId/transactions/:transactionId/customers/:customerTransactionId/review';
export const TRANSACTION_WHOLESALE_PATH =
  '/companies/:companyId/transactions/:transactionId/wholesale/:wholesaleId';
export const SPOT_RATES_PATH = '/rates';
export const WHOLESALE_TRANSACTION_RECEIPT_PATH =
  '/companies/:companyId/transaction-groups/:transactionGroupId/wholesale-receipts';
export const TRANSACTION_RECEIPT_PATH =
  '/companies/:companyId/transaction-groups/:transactionGroupId/receipts';
export const TRANSFER_RECEIPT_PATH =
  '/companies/:companyId/transaction-groups/:transactionGroupId/transfer-receipts';
export const ACCOUNTING_PATH = '/companies/:companyId/accounting';
export const DOWNLOADS_PATH = '/companies/:companyId/downloads';
export const DOMESTIC_TRANSACTION_PATH =
  '/companies/:companyId/branches/:branchId/domestic-transactions/:domesticTransactionId';
export const DOMESTIC_TRANSACTION_TYPES_PATH =
  '/companies/:companyId/domestic-transaction-types';
export const DOMESTIC_TRANSACTION_RECEIPT_PATH =
  '/companies/:companyId/domestic-transactions/:domesticTransactionId/receipts';
export const BALANCE_OPENING_CLOSING_PATH =
  '/companies/:companyId/branches/:branchId/opening-closing-balance';
export const CURRENCY_BALANCE_PATH =
  '/companies/:companyId/branches/:branchId/currency-balance';
export const CURRENCY_BALANCE_BY_BRANCH_PATH =
  '/companies/:companyId/currency-balance-by-branch';
export const BALANCE_PATH =
  '/companies/:companyId/branches/:branchId/balances/:balanceId';
export const TRANSFER_BRANCH_PATH =
  '/companies/:companyId/branches/:branchId/transfer-branch';
export const ORDER_PATH =
  '/companies/:companyId/branches/:branchId/orders/:orderId';
export const ORDER_GROUP_RECEIPT_PATH =
  '/companies/:companyId/order-groups/:orderGroupId/receipts';
export const CUSTOMER_ORDER_GROUP_PATH =
  '/companies/:companyId/branches/:branchId/order-groups/:orderGroupId/customers/:customerTransactionId';
export const CURRENCY_TRANSACTION_REPORT_PATH =
  '/companies/:companyId/currency-transaction-reports/:currencyTransactionReportId';
export const MULTIPLE_TRANSACTIONS_PATH =
  '/companies/:companyId/multiple-transactions/:multipleTransactionId';
export const OFAC_SANCTIONS_PATH =
  '/companies/:companyId/ofac-sanctions/:ofacId';

export const CURRENCY_API_PATH = '/companies/:companyId/currencies-api';

export const makeCompanyPath = (companyId = 'new') =>
  COMPANY_PATH.replace(':companyId', companyId);
export const makeCompanyEditPath = (companyId) =>
  COMPANY_PATH.replace(':companyId', `${companyId}/edit`);
export const makeBranchPath = (companyId, branchId = 'new') =>
  BRANCH_PATH.replace(':companyId', companyId).replace(':branchId', branchId);
export const makeBranchTransactionPath = (
  companyId,
  branchId,
  transactionId,
  customerTransactionId
) =>
  BRANCH_TRANSACTION_PATH.replace(':companyId', companyId)
    .replace(':branchId', branchId)
    .replace(':transactionId', transactionId)
    .replace(':customerTransactionId', customerTransactionId);
export const makeBranchTransactionReviewPath = (
  companyId,
  branchId,
  transactionId,
  customerTransactionId
) =>
  BRANCH_TRANSACTION_REVIEW_PATH.replace(':companyId', companyId)
    .replace(':branchId', branchId)
    .replace(':transactionId', transactionId)
    .replace(':customerTransactionId', customerTransactionId);

export const makeTransactionPath = (companyId, transactionId) =>
  TRANSACTION_PATH.replace(':companyId', companyId).replace(
    ':transactionId',
    transactionId
  );
export const makeDomesticTransactionPath = (
  companyId,
  branchId,
  domesticTransactionId
) =>
  DOMESTIC_TRANSACTION_PATH.replace(':companyId', companyId)
    .replace(':branchId', branchId)
    .replace(':domesticTransactionId', domesticTransactionId);
export const makeBalancePath = (companyId, branchId, balanceId) =>
  BALANCE_PATH.replace(':companyId', companyId)
    .replace(':branchId', branchId)
    .replace(':balanceId', balanceId);

export const makeTransactionWholesalePath = (
  companyId,
  transactionId,
  wholesaleId
) =>
  TRANSACTION_WHOLESALE_PATH.replace(':companyId', companyId)
    .replace(':transactionId', transactionId)
    .replace(':wholesaleId', wholesaleId);
export const makeCustomerPath = (companyId, customerId) =>
  CUSTOMER_PATH.replace(':companyId', companyId).replace(
    ':customerId',
    customerId
  );
export const makeBusinessPath = (companyId, businessId) =>
  BUSINESS_PATH.replace(':companyId', companyId).replace(
    ':businessId',
    businessId
  );
export const makeUserPath = (companyId, userId = 'new') =>
  USER_PATH.replace(':companyId', companyId).replace(':userId', userId);
export const makeCurrencyPath = (companyId, currencyCode) =>
  CURRENCY_PATH.replace(':companyId', companyId).replace(
    ':currencyCode',
    currencyCode
  );
export const makeWholesaleTransactionReceiptPath = (
  companyId,
  transactionGroupId
) =>
  WHOLESALE_TRANSACTION_RECEIPT_PATH.replace(':companyId', companyId).replace(
    ':transactionGroupId',
    transactionGroupId
  );
export const makeTransactionReceiptPath = (companyId, transactionGroupId) =>
  TRANSACTION_RECEIPT_PATH.replace(':companyId', companyId).replace(
    ':transactionGroupId',
    transactionGroupId
  );
export const makeTransferReceiptPath = (companyId, transactionGroupId) =>
  TRANSFER_RECEIPT_PATH.replace(':companyId', companyId).replace(
    ':transactionGroupId',
    transactionGroupId
  );
export const makeDomesticTransactionReceiptPath = (
  companyId,
  domesticTransactionId
) =>
  DOMESTIC_TRANSACTION_RECEIPT_PATH.replace(':companyId', companyId).replace(
    ':domesticTransactionId',
    domesticTransactionId
  );
export const makeOrderPath = (companyId, branchId, orderId) =>
  ORDER_PATH.replace(':companyId', companyId)
    .replace(':branchId', branchId)
    .replace(':orderId', orderId);
export const makeOrderGroupReceiptPath = (companyId, orderGroupId) =>
  ORDER_GROUP_RECEIPT_PATH.replace(':companyId', companyId).replace(
    ':orderGroupId',
    orderGroupId
  );
export const makeCustomerOrderGroupPath = (
  companyId,
  branchId,
  orderGroupId,
  customerTransactionId
) =>
  CUSTOMER_ORDER_GROUP_PATH.replace(':companyId', companyId)
    .replace(':branchId', branchId)
    .replace(':orderGroupId', orderGroupId)
    .replace(':customerTransactionId', customerTransactionId);

export const getCompanyId = () => {
  const { companyId } = useParams();
  const { user } = useAuth0();

  return companyId || user?.company?.id || 'unassigned-company';
};
export const getBranchId = () => {
  const { branchId } = useParams();
  const { user } = useAuth0();

  return branchId || user?.home_branch || user?.branches?.[0]?.id || '';
};
export const makeCurrencyTransactionReportPath = (
  companyId,
  currencyTransactionReportId
) =>
  CURRENCY_TRANSACTION_REPORT_PATH.replace(':companyId', companyId).replace(
    ':currencyTransactionReportId',
    currencyTransactionReportId
  );
export const makeMultipleTransactionsPath = (
  companyId,
  multipleTransactionId
) =>
  MULTIPLE_TRANSACTIONS_PATH.replace(':companyId', companyId).replace(
    ':multipleTransactionId',
    multipleTransactionId
  );
export const makeOFACSanctionsPath = (companyId, ofacId) =>
  OFAC_SANCTIONS_PATH.replace(':companyId', companyId).replace(
    ':ofacId',
    ofacId
  );
