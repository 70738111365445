import React, {useEffect} from 'react';
import { useSetRecoilState } from 'recoil';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import PageSpinner from '../spinners/PageSpinner';
import { accessTokenState } from '../../atoms/auth';

const PrivateRoute = ({ children }) => {
  const setAccessToken = useSetRecoilState(accessTokenState);
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      } catch (e) {
        setAccessToken(null);
        console.error(e.message);
      }
    })();
  }, [getAccessTokenSilently, user?.sub]);

  return <>{children}</>;
};

export default withAuthenticationRequired(PrivateRoute, {
  onRedirecting: PageSpinner,
  returnTo: `${location.pathname}${location.search}`
});
