import { Spin } from 'antd';

const PageSpinner = () => (
  <Spin
    style={{
      width: '100%',
      marginTop: 'calc(50vh - 27px)'
    }}
    tip="Loading..."
    size="large"
  >
    <span />
  </Spin>
);

export default PageSpinner;
